import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

$('#check-code-button').on('click', function () {
  var $button = $(this);
  var $error = $('#enter-code-error');
  var code = $('#enter-code').val();
  if (!code.trim()) {
    $error.text('Please enter a code.').show();
    return;
  }
  $error.hide();
  $button.prop('disabled', true).html('<i class="fa fa-spin fa-spinner me-2"></i>Loading...');
  $.post('/codes/' + code + '/redeem')
    .done(function (data) {
      //console.log('done', data);
      if (data) {
        if (data.error) {
          $error.text(data.error).show();
        } else if (data.success) {
          location.href = '/';
        }
      }
    })
    .fail(function (data) {
      //console.log('fail', data);
      if (data.status === 404) {
        $error.text('Invalid code.').show();
      }
    })
    .always(function (data) {
      //console.log('always', data);
      $button.prop('disabled', false).text('Enter');
    });
});

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = jQuery.trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

var csrftoken = getCookie('csrftoken');

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader('X-CSRFToken', csrftoken);
    }
  },
});

$(function () {
  $('[data-bs-toggle="tooltip"]').tooltip();
});
